.main_layout {
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.creator_search_component {
  margin-bottom: 20px;
}

.page_title {
  display: flex;
  color: black;
  font-size: 30px;
  align-items: start;
  font-weight: 300;
  margin: 0;
  margin-bottom: 25px;
}

.search_layout {
  display: flex;
  flex-direction: row;
}

.searchbar {
  display: flex;
  align-items: center;
  height: 50px;
  flex-grow: 1;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  margin-right: 15px;

  border-radius: 5px;
  padding-left: 15px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.searchbar::placeholder {
  opacity: 0.6;
  font-weight: 200;
}

.search_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 120px;
  color: white;
  background-color: rgba(44, 78, 133, 1);
  border-radius: 5px;
  cursor: pointer;
}

.creator_list_fields {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 100px;
  margin-top: 50px;
}

.creator_list {
  min-height: 1400px;
}

.creator_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
}

.tile_section {
  display: flex;
  height: 100%;
  width: 15%;
  align-items: center;
}

.connect_button_section {
  display: flex;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: end;
}

.export {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(44, 78, 133, 0.8);
  color: white;
  width: 90px;
  height: 30px;
  border-radius: 5px;
  font-size: 12px;
}

.checkbox_field {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 60px;
  margin-left: 15px;
}

.checkbox {
  width: 15px;
  height: 15px;
  border: 1px solid rgba(0, 0, 0, 0.75);
}

.tab_layout {
  display: flex;
  flex-direction: column;
  /* padding: 24px; */
  width: 100%;
  box-sizing: border-box;
}

.tabs {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-bottom: 1px solid rgba(44, 78, 133, 0.2);
  min-height: 60px;
}
/* background: rgba(255, 255, 255, 0.15); */

.tab {
  padding: 10px 15px;
  font-size: 1.2em;
  font-weight: 300;
  color: black;
  transition: color 0.3s ease;
  cursor: pointer;
  white-space: nowrap;
  position: relative;
}

.tab:not(:last-child) {
  margin-right: 15px;
}

.tab:hover {
  color: rgba(44, 78, 133, 1);
}

.active {
  color: rgba(44, 78, 133, 1);
}

.underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  background: rgba(44, 78, 133, 1);
  transition: left 0.3s ease, width 0.3s ease;
}
