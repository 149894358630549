.home_components {
  width: 100vw;
  height: 100vh;
}

.landing_image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.cover_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.navbar_box {
  height: 75px;
  /* background: rgba(255, 255, 255, 1); */
}

.home_header_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65); /* Black with 40% opacity */
  /* border-radius: 10px; */
  z-index: 1;
}

.landing_header {
  /* background: rgba(255, 255, 255, 0.5); */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.back_button {
  position: absolute;

  left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  width: 200px;
  /* height: 100%;
  margin-left: 20px; */
}

.back_icon {
  height: 50%;
}

.back_text {
  font-size: 20x;
  color: white;
  margin-left: 7.5px;
}

.auth_content_box {
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8); /* Black with 40% opacity */
  width: 30%;
  height: 50%;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 75px; */
}

.user_info_layout {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 80%;
}

.user_info_title {
  color: white;
  margin: 0;
}
.input_box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;

  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 10px;
  border-radius: 5px;
  color: white;
}
.input_box_text {
  margin: 0;
}
