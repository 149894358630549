.main_layout {
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: calc(100vh - 80px);
  overflow-y: auto;
}

.creator_search_component {
}

.page_title {
  display: flex;
  color: black;
  font-size: 30px;
  align-items: start;
  font-weight: 300;
  margin: 0;
  margin-bottom: 25px;
}

.search_layout {
  display: flex;
  flex-direction: row;
}

.platform_filter {
  display: flex;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  height: 50px;
  width: 100px;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
}

.platform_filter:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dropdown_menu {
  position: absolute;
  top: calc(100% + 15px);
  left: -1px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  opacity: 0;

  transform: scale(0); /* Adjusted from translateY */
  transform-origin: top left; /* Ensure the growth starts from the top left corner */
  transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  visibility: hidden;
  transition-delay: 0s, 0s; /* Ensures visibility transitions immediately */
  user-select: none;
  z-index: 999;
}

.platform_filter.open .dropdown_menu {
  opacity: 1;
  transform: scale(1); /* Adjusted from translateY */
  visibility: visible;
  user-select: none;
}

.platform_item {
  display: flex;
  width: 200px;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px;
}

.platform_item:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.platform_item_selected {
  background-color: rgba(0, 88, 220, 0.1);
}

.platform_text {
  margin-left: 20px;
  font-size: 17px;
  color: black;
  flex-grow: 1;
  text-align: start;
}

.platform_locked_icon {
  width: 15px;
  height: 15px;
  object-fit: cover;
  /* margin-right: 7.5px; */
  opacity: 0.7;
}

.social_filter_icon {
  width: 30px;
  height: 30px;
  object-fit: cover;
  user-select: none;
}

.down_icon {
  width: 22px;
  height: 22px;
  object-fit: cover;
  opacity: 0.75;
  user-select: none;
}

.searchbar {
  display: flex;
  align-items: center;
  height: 50px;
  flex-grow: 1;
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  margin-right: 15px;
  margin-left: 15px;
  border-radius: 5px;
  padding-left: 15px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.searchbar::placeholder {
  font-size: 16px;
  margin: 0;
  font-style: italic;
}

.search_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 120px;
  color: white;
  background-color: var(--color-scheme-hex);
  border-radius: 5px;
  cursor: pointer;
}

.filter_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 120px;
  color: white;
  box-sizing: border-box;
  border: 1.5px solid var(--color-scheme-hex);
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  color: var(--color-scheme-hex);
}

.search_button:hover {
  background-color: rgba(0, 88, 220, 0.9);
}

.default_filter_item {
  /* position: relative; */
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* border: 1.5px solid rgba(0, 88, 220, 0.5); */
  border: 1px solid rgba(0, 0, 0, 0.4);
  /* padding: 8px; */
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;
  box-sizing: border-box;
  white-space: nowrap; /* Prevent text wrapping */
  border-radius: 5px;
  user-select: none;
  cursor: pointer;
}

.default_filter_item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.rotate180 {
  transform: rotate(180deg);
  transition: transform 0.1s ease-in-out;
}

.filter_title {
  font-size: 14px;
  margin-right: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.selected_filter_title {
  font-size: 14px;
  margin-right: 15px;
  font-weight: 400;
  color: rgba(0, 88, 220, 1);
}

.creator_list_fields {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 50px;
  margin-top: 20px;
  padding-right: 15px;
}

.creator_list {
  /* min-height: 1400px; */
  margin-bottom: 20px;
  /* padding-right: 15px; */
}

.creator_tile_margin {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-right: 15px;
}

.creator_tile_layout {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 90px;
  /* padding-left: 15px; */

  cursor: pointer;
}

.creator_tile_layout:hover {
  /* background-color: rgba(0, 88, 220, 0.04); */
}

.creator_tile_layout_checked {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 90px;
  /* padding-left: 15px; */
  /* padding-right: 15px; */
  cursor: pointer;
  background-color: rgba(0, 88, 220, 0.1);
}

.creator_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 25%;
}

.account_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20%;
}

.creator_profile_picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.creator_display_names {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.creator_name {
  margin: 0;
  color: black;
}

.creator_username {
  margin: 0;
  color: black;
  opacity: 0.7;
}

.tile_section {
  display: flex;
  height: 100%;
  width: 17.5%;
  align-items: center;
  cursor: pointer;
}

.tile_date_section {
  display: flex;
  height: 100%;
  width: 22.5%;
  align-items: center;
  cursor: pointer;
}

.tile_title_section {
  display: flex;
  height: 100%;
  width: 17.5%;
  align-items: center;
  cursor: pointer;
}

.tile_title_date_section {
  display: flex;
  height: 100%;
  width: 22.5%;
  align-items: center;
  cursor: pointer;
}

.connect_button_section {
  display: flex;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: end;
}

.connect_button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--color-scheme-hex); */
  color: white;
  width: 90px;
  height: 30px;
  border-radius: 5px;
  font-size: 14px;
  user-select: none;

  border: 1px solid rgba(44, 78, 133, 0.6);
  color: rgba(44, 78, 133, 1);
  background-color: white;
}

.connect_button:hover {
  background-color: var(--color-scheme-hex);
  color: white;
}

/* .connect_button_text {
  color: var(--color-scheme-hex);
  font-size: 14px;
  margin: 0;
} */

.export {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 88, 220, 0.8);
  color: white;
  width: 90px;
  height: 30px;
  border-radius: 5px;
  font-size: 12px;
}

.creator_stat {
  color: black;
  opacity: 0.75;
  font-weight: 300;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 9997;
  pointer-events: none;
}

.overlay.visible {
  opacity: 1;
  pointer-events: auto;
}

.filter_sidebar {
  position: fixed; /* Changed from absolute to fixed */
  top: 0;
  right: 0; /* Ensure it starts from the right */
  width: 65vw;
  /* background-color: var(--color-scheme-hex); */
  background-color: white;
  height: 100vh;
  transform: translateX(100%);
  transition: transform 0.2s ease-in-out;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5) !important;
  z-index: 9998;
}

.filter_sidebar.visible {
  transform: translateX(0); /* Adjusted value */
}

.filter_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  padding: 0 20px;
}

.filter_header_title {
  margin: 0;
  font-size: 25px;
  font-weight: 600;
}

.close_icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.filter_content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* gap: 50px; */
  justify-items: center;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  height: 77%;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 20px;
}

.filter_column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 90%;
}

.section_title {
  display: flex;
  align-items: center;
  /* height: 60px; */
}

.section_title_text {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 20px;
}

.filter_cell {
  height: 50px;
  position: relative;
  margin-bottom: 10px;
}

.filter_options {
  margin-top: 17.5px;
}

.item_container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.item_title {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  user-select: none;
}

.item_title_hovered {
  margin: 0;
  user-select: none;
  color: rgba(0, 88, 220, 1);
  font-size: 18px;
  font-weight: 500;
}

.filter_item_selected {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  height: 35px;
  position: relative;
  border-radius: 30px;
  outline: 1px solid rgba(0, 88, 220, 0.8);
  box-sizing: border-box;
  padding: 0 15px;
}

.filter_item {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  height: 35px;
  position: relative;
}

.filter_item_hovered {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  height: 35px;
  position: relative;
  border-radius: 30px;
  outline: 1px solid rgba(0, 88, 220, 0.8);
  box-sizing: border-box;
  padding: 0 15px;
}

.filter_item:hover {
  /* border-radius: 30px;
  outline: 1px solid rgba(0, 88, 220, 0.8);
  box-sizing: border-box;
  padding: 0 15px; */
}

.filter_add_icon {
  width: 14px;
  height: 14px;
  margin-right: 14px;
  user-select: none;
}

.filter_down_icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.filter_selected_icon_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.filter_selected_icon {
  width: 15px;
  height: 15px;
  object-fit: cover;
}

.filter_cell .dropdown {
  /* position: absolute;
  top: calc(100% + 15px);
  left: 0; */
  width: 200px; /* Set the width of the dropdown */
  max-height: 0; /* Initially, there should be no height */
  overflow: hidden; /* Ensures content does not spill out */
  background-color: white; /* The desired background color */
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5) !important;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  opacity: 0;
  transform: scale(0);
  transform-origin: top left;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out,
    height 0s ease-in-out 0.1s; /* Delay the height transition to sync with the transform */
  visibility: hidden;
  user-select: none;
  z-index: 9999;
}

.filter_cell .dropdownOpen {
  max-height: 60px; /* Set the desired height for when the dropdown is open */
  opacity: 1;
  transform: scale(1);
  visibility: visible;
  transition-delay: 0s, 0s, 0s; /* No delay needed when opening */
}

.filter_cell .dropdown > div {
  height: 100%;
  /* Any other styling you want for the dropdown content */
}

.filter_dropdown_component {
  display: flex;

  flex-direction: column;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 80%;
  z-index: 9999;
  box-sizing: border-box;
  /* padding: 1px; */
  /* background-color: red; */
}

.filter_dropdown_range_component {
  display: flex;

  flex-direction: column;

  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 80%;
  /* z-index: 9999; */
  box-sizing: border-box;
  /* padding: 1px; */
  /* background-color: red; */
}

.filter_range_dropdown_component {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.range_dropdown_box {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* Changed From 35 to 50 */
  height: 50px;
  width: 47%;
  border-radius: 5px;
  cursor: pointer;
}

.input_dropdown_box {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  /* padding: 0 15px; */
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* Changed From 35 to 50 */
  height: 50px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.single_dropdown_box {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 10px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* Changed From 35 to 50 */
  height: 50px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.single_dropdown_box:hover {
  background-color: rgba(0, 0, 0, 0.025);
}

.range_dropdown_box:hover {
  background-color: rgba(0, 0, 0, 0.025);
}

.filter_sidebar_dropdown_box {
  /* min-height: 50px; */
  margin-top: -10px;
  width: 100%;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
  margin-bottom: 25px;
}

.filter_value {
  flex-grow: 1;
  border: none;
  outline: none;
  margin-left: 12px;
  user-select: none;
  font-size: 16px;
}

.filter_value::placeholder {
  font-size: 16px;
  font-style: italic;
}

.filter_value_dropdown {
  border: none;
  outline: none;
  user-select: none;
}

.dropdown_arrow {
  width: 15px;
  height: 15px;
  user-select: none;
}

.dropdown_menu_box {
  position: absolute;
  top: calc(100% + 5px); /* Push the dropdown right below the toggle element */
  left: 0;
  z-index: 10;
  background: #fff; /* Set a background so it stands out over other content */
  border: 1px solid #ccc; /* Just some styling */
  border-radius: 4px; /* Optional, for rounded corners */
  width: 100%; /* This can be a fixed width or percentage */
  max-height: 275px;
  overflow: auto;
  /* Add box-shadow or other properties for better visual appearance */
}

.dropdown_menu_item {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 10px;
}

.dropdown_menu_item:hover {
  background-color: rgba(0, 88, 220, 0.1);
}

.dropdown_menu_text {
  margin: 0;
  color: black;
  text-align: start;
  font-size: 13px;
  width: 85%;
  font-weight: 500;
  user-select: none;
}

.dropdown_below {
  /* This class can be used for additional specific styling if needed */
}

.filter_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 13%;
  padding-left: 22px;
  padding-right: 22px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.filter_search_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 88, 220, 1);
  border-radius: 5px;
  color: white;
  width: 120px;
  height: 50%;
  cursor: pointer;
}

.filter_search_button:hover {
  background-color: rgba(0, 88, 220, 0.9);
}

.remove_filter_button {
  text-decoration: underline;
  color: black;
  cursor: pointer;
  margin: 0;
  margin-left: 20px;
  width: 150px;
}

.remove_filter_button:hover {
  opacity: 0.7;
}

.current_filter_options_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 70px;
  margin-top: 17.5px;
  background-color: rgba(0, 0, 0, 0.065);
  padding: 20px 25px;
  box-sizing: border-box;
  border-radius: 5px;
}

.current_filter_options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  height: 100%;
  flex-grow: 1;
}

.current_filter_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.2); */
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  box-sizing: border-box;
  padding: 10px 20px;
}

.remove_filter_item {
  width: 12px;
  height: 12px;
  object-fit: cover;
  margin-left: 15px;
  cursor: pointer;
  box-sizing: border-box;
}

.remove_filter_item:hover {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0.5;
  border-radius: 50%;
  padding: 2px;
}

.current_filter_item_text {
  font-size: 16px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.8);
  margin: 0;
  text-align: start;
}

.default_filter_dropdown {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 10px 10px;
  width: 240px;
  min-height: 50px;
  background-color: white;
  /* border: 1px solid rgba(0, 0, 0, 0.065); */
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  border-radius: 2.5px;
  box-shadow: 0px 0px 3.5px rgba(0, 0, 0, 0.5) !important;
}

.default_filter_dropdown_wrapper {
  position: relative;
}

.selected_options_list {
  margin-top: 10px;
  margin-bottom: 0px;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.selected_options_item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px 10px;
}

.selected_options_text {
  margin: 0;
  font-size: 12px;
  color: black;
  font-weight: 300;
  text-align: start;
}

.selected_options_remove {
  width: 8px;
  height: 8px;
  object-fit: cover;
  margin-left: 8px;
  cursor: pointer;
}

.slider_component {
  width: 95%;
}

.slider_dropdown_text {
  text-align: left;
  font-size: 15px;
  width: 100%;
}

.slider_dropdown_value_text {
  /* padding: 0 4px; */
  /* width: 10px; */
}

.sliderContainer {
  width: 100%;
}

/* .slider {
  width: 100%;
  height: 25px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
} */

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: rgba(0, 0, 0, 0.2); /* Blue background for track */
  outline: none;
  opacity: 0.8;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 10px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgba(0, 88, 220, 1); /* Green background for thumb */
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #4caf50;
  cursor: pointer;
}

.slider:hover {
  opacity: 1;
}

.loading_layout {
  margin-top: 200px;
}

.checkbox_field {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin-left: 15px;
}

.checkbox {
  width: 15px;
  height: 15px;
  border: 1px solid rgba(0, 0, 0, 0.75);
  /* border: 1px solid rgba(0, 88, 220, 0.75); */
}

/* checkbox settings 👇 */

.ui_checkbox {
  --primary-color: #1677ff;
  --secondary-color: #fff;
  --primary-hover-color: #4096ff;
  /* checkbox */
  --checkbox-diameter: 18px;
  --checkbox-border-radius: 2px;
  --checkbox-border-color: rgba(0, 0, 0, 0.5);
  --checkbox-border-width: 1px;
  --checkbox-border-style: solid;
  /* checkmark */
  --checkmark-size: 1.2;
}

.ui_checkbox,
.ui_checkbox *,
.ui_checkbox *::before,
.ui_checkbox *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.ui_checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: var(--checkbox-diameter);
  height: var(--checkbox-diameter);
  border-radius: var(--checkbox-border-radius);
  background: var(--secondary-color);
  border: var(--checkbox-border-width) var(--checkbox-border-style)
    var(--checkbox-border-color);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
}

.ui_checkbox::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5)
    var(--primary-color);
  box-shadow: 0 0 0 calc(var(--checkbox-diameter) / 2.5) var(--primary-color);
  border-radius: inherit;
  opacity: 0;
  -webkit-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  -o-transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  transition: all 0.5s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.ui_checkbox::before {
  top: 40%;
  left: 50%;
  content: "";
  position: absolute;
  width: 4px;
  height: 7px;
  border-right: 2px solid var(--secondary-color);
  border-bottom: 2px solid var(--secondary-color);
  -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(0);
  -ms-transform: translate(-50%, -50%) rotate(45deg) scale(0);
  transform: translate(-50%, -50%) rotate(45deg) scale(0);
  opacity: 0;
  -webkit-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6),
    opacity 0.1s;
  -o-transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
}

/* actions */

.ui_checkbox:hover {
  border-color: var(--primary-color);
}

.ui_checkbox:checked {
  background: var(--primary-color);
  border-color: transparent;
}

.ui_checkbox:checked::before {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) rotate(45deg)
    scale(var(--checkmark-size));
  -ms-transform: translate(-50%, -50%) rotate(45deg)
    scale(var(--checkmark-size));
  transform: translate(-50%, -50%) rotate(45deg) scale(var(--checkmark-size));
  -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  -o-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.ui_checkbox:active:not(:checked)::after {
  -webkit-transition: none;
  -o-transition: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: none;
  opacity: 1;
}

.creator_list_controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.creator_list_right_controls {
  display: flex;
  align-items: center;
  position: relative;
}

.creator_list_left_controls {
  display: flex;
  align-items: center;
}

.creator_found_title {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}

.creator_selected_text {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.sort_section {
  display: flex;
  align-items: center;
  margin-left: 25px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.sort_section:hover {
  background-color: rgba(0, 0, 0, 0.065);
}

.sort_icon {
  width: 18px;
  height: 18px;
  object-fit: cover;
  margin-right: 5px;
  user-select: none;
}

.flipped {
  transform: rotate(180deg);
}

.sort_text {
  margin: 0;
  font-weight: 300;
  user-select: none;
}

.chosen_sort_text {
  font-weight: 500;
  user-select: none;
}

.dropdown_arrow_sort {
  width: 20px;
  height: 20px;
  user-select: none;
  margin-left: 10px;
}

.export_section {
  display: flex;
  align-items: center;
  margin-left: 40px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;

  opacity: 0.3;
  /* margin-right: 10px; */
}

.export_section_active {
  display: flex;
  align-items: center;
  margin-left: 40px;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  opacity: 1;
  /* margin-right: 10px; */
}

.export_section_active:hover {
  background-color: rgba(0, 0, 0, 0.065);
}

.locked_icon {
  width: 15px;
  height: 15px;
  object-fit: cover;
  margin-right: 7.5px;
  opacity: 0.7;
}

.export_text {
  margin: 0;
  font-weight: 300;
  opacity: 0.7;
}

.sortDropdown {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 10px;
  width: 180px;
}

.sortDropdownItem {
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  height: 45px;
  box-sizing: border-box;
  user-select: none;
  /* Add hover effects, etc. */
}

.sortDropdownItemClicked {
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  height: 45px;
  box-sizing: border-box;
  user-select: none;
  background-color: rgba(0, 88, 220, 0.1);
}

.sortDropdownItem:hover {
  background-color: rgba(0, 0, 0, 0.07);
}

.connect_button_section {
  display: flex;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: end;
}

.connect_button_all {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--color-scheme-hex); */
  color: white;
  width: 90px;
  height: 30px;
  border-radius: 5px;

  color: var(--color-scheme-hex);
  font-size: 16px;
  user-select: none;
}
/* 
.connect_button:hover {
  background-color: var(--color-scheme-hex);
  color: white;
} */

.connect_all_button {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--color-scheme-hex); */
  color: white;
  width: 90px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid var(--color-scheme-hex);
  color: var(--color-scheme-hex);
  font-size: 14px;
  user-select: none;
  margin-left: 20px;
}

.connect_all_button:hover {
  background-color: var(--color-scheme-hex);
  color: white;
}

.arrow_icon {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  object-fit: cover;
  transform: rotate(180deg);
}

.field_titles {
  font-size: 15px;
  font-weight: 300;
}

.field_titles_selected {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-scheme-hex);
}

.arrow_icon_hover {
  margin-left: 5px;
  width: 20px;
  height: 20px;
  object-fit: cover;
  transform: rotate(180deg);
  opacity: 0.7;
}

.element {
  background-color: var(--color-scheme-hex);
}

.control_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.ignore_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.025);
  font-size: 14px;
  user-select: none;
}

.accept_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid rgba(0, 160, 0, 0.5);
  color: rgba(0, 160, 0, 0.8);
  background-color: rgba(0, 160, 0, 0.055);
  font-size: 14px;
  user-select: none;
}

.ignore_button:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.8);
  background-color: rgba(0, 0, 0, 0.095);
}

.accept_button:hover {
  border: 1px solid rgba(0, 160, 0, 0.5);
  color: rgba(0, 160, 0, 0.9);
  background-color: rgba(0, 160, 0, 0.125);
}

.chat_button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 90px;
  height: 30px;
  border-radius: 25px;
  font-size: 14px;
  user-select: none;
  border: 1px solid rgba(44, 78, 133, 0.6);
  color: rgba(44, 78, 133, 1);
  background-color: white;
}

.chat_button:hover {
  border: 1px solid rgba(44, 78, 133, 0.6);
  color: rgba(44, 78, 133, 1);
  background-color: rgba(44, 78, 133, 0.1);
}

.more_button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2.5px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid rgba(44, 78, 133, 0.6);
  color: rgba(44, 78, 133, 1);
  background-color: white;
}

.more_button:hover {
  border: 1px solid rgba(44, 78, 133, 0.6);
  color: rgba(44, 78, 133, 1);
  background-color: rgba(44, 78, 133, 0.1);
}

.more_button_dot {
  width: 2px;
  height: 2px;
  background-color: rgba(44, 78, 133, 1);
  border-radius: 50%;
}

.more_layout {
  position: relative;
}

.menu {
  position: absolute;
  right: 0px;
  top: 45px;
  width: 150px;
  /* height: 200px; */
  background-color: white;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  /* border: 1px solid black; */
  z-index: 1;
}

.menu_item {
  display: flex;
  align-items: center;
  height: 50px;
  user-select: none;
}

.menu_item:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.menu_item_text {
  color: black;
  opacity: 0.65;
  margin: 0;
  margin-left: 10px;
  user-select: none;
}

.rescind_button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 90px;
  height: 30px;
  border-radius: 25px;
  font-size: 14px;
  user-select: none;
  border: 1px solid rgba(200, 0, 0, 0.6);
  color: rgba(200, 0, 0, 1);
  background-color: white;
}

.rescind_button:hover {
  border: 1px solid rgba(200, 0, 0, 0.6);
  color: rgba(200, 0, 0, 1);
  background-color: rgba(200, 0, 0, 0.1);
}
