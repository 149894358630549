.home_components {
  width: 100vw;
  height: 100vh;
}

.white_image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.landing_image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
}

.city_back_image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.home_header_content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65); /* Black with 40% opacity */
  /* border-radius: 10px; */
  z-index: 1;
}

.landing_header {
  /* background: rgba(255, 255, 255, 0.5); */
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login_box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 70%;
  height: 70%;
}

.login_box_image {
  width: 50%;
  height: 100%;
  object-fit: cover;
}

.login_box_content {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: white;
}

.login_box_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  /* padding: 5px; */
}

.login_box_controls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.login_box_controls_content {
  display: flex;
  flex-direction: column;

  width: 80%;
  height: 70%;
  /* justify-content: space-between; */
}

.login_box_logo {
  height: 50%;
  padding: 5px;
}

.login_create_account {
  color: #4b4b4b;
  margin: 0;
  padding: 5px;
}

.login_box_titles {
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: start;
  margin-bottom: 15px;
}

.login_box_title {
  margin: 0;
  font-size: 35px;
  color: #084e8e;
}

.login_box_subtitle {
  margin: 0;
  font-size: 15px;
  color: #4b4b4b;
}

.login_box_components {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login_box_outline {
  width: 100%;
  height: 40px;
  border: 1px solid #084e8e;
  margin-bottom: 15px;
}
.login_box_bottom_buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.login_box_submit_button {
  background-color: #084e8e;
  width: 48%;
  border-radius: 10px;
}
.login_box_submit_button_text {
  color: white;
}

.login_box_forgot_password {
  width: 48%;
}
.login_box_forgot_password_text {
  color: #4b4b4b;
}

/* .user_info_layout {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 80%;
}

.user_info_title {
  color: white;
  margin: 0;
}
.input_box {
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;

  background-color: rgba(0, 0, 0, 0.5);
  margin-top: 10px;
  border-radius: 5px;
  color: white;
}
.input_box_text {
  margin: 0;
} */

.auth_input_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 100%;
  /* background-color: red; */
}

.input_box_icon {
  width: 30px;
  height: 30px;
  /* background-color: red; */
}

.input_box {
  background-color: white;
  border: none;
  height: 80%;
  width: 70%;
}

/* NEW PAGE */

.home_components {
  width: 100vw;
  /* height: 100vh; */
}

.landing_image {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.cover_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.home_header_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.page_title {
  color: #084e8e;
  font-weight: 200;
  font-size: 30px;
  margin-bottom: 50px;
}
.join_waitlist {
  color: #084e8e;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 15px;
}

.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: start;
  width: 45%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  /* border-left: 2px solid #003d82; */
  box-sizing: border-box;
  padding: 30px;
}

.main_copywriting {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.email_input {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(8, 78, 142, 0.2);
  margin-bottom: 15px;
  user-select: none;
}

.input {
  height: 80%;
  flex-grow: 1;
  background-color: transparent;
  user-select: none;
  margin-left: 10px;
  border: none;
  outline: none;
}

.input::placeholder {
  color: black;
  opacity: 0.2;
  font-weight: 400;
}

.join_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(8, 78, 142, 0.75);
  color: white;
  cursor: pointer;
}

.join_button:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(8, 78, 142, 1);
  color: white;
}

.join_button_inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: rgba(8, 78, 142, 0.2);
  color: #003d82;
  opacity: 0.5;
}

.join_button_text {
  font-size: 15px;
  font-weight: 400;
}

@media (max-width: 1100px) {
  .sidebar {
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;
    background-color: white;
    border-right: 2px solid #084e8e;
    box-sizing: border-box;
    padding: 30px;
  }
}

.note {
  color: #084e8e;
  opacity: 0.5;
  font-size: 13px;
  font-weight: 300;
  text-align: left;
}

.logo {
  width: 150px;
  margin-bottom: 10px;
  object-fit: cover;
}
