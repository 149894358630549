.portal_navbar {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 60px;
  z-index: 99999;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); /* Add the box shadow */
}

.portal_navbar_sidebar_extension {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  /* padding: 0 10px; */
}

.portal_navbar_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.portal_navbar_left_content {
  display: flex;
  flex-direction: row;
}

.portal_navbar_right_content {
  display: flex;
  flex-direction: row;
  align-items: end;
  position: relative;
}

.portal_navbar_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 15px;
}

.portal_navbar_account {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 50px;
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-right: 15px;
}

.navbar_item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  /* background-color: rgba(0, 0, 0, 0.1); */
  margin-right: 20px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
}

.navbar_item:hover {
  transition: box-shadow 0.3s;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 61, 130, 0.06);
}

.navbar_icon {
  width: 45%;
  height: 45%;
  opacity: 0.6;
  object-fit: cover;
  user-select: none;
}

.account_navbar_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  /* width: 100px; */
  /* background-color: rgba(0, 0, 0, 0.1); */
  margin-right: 20px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  padding: 0 10px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
}

.account_navbar_item:hover {
  transition: box-shadow 0.3s;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 61, 130, 0.06);
}

.dropdown_icon_icon {
  margin-left: 15px;
  width: 20px;
  height: 20px;
  opacity: 0.75;
  user-select: none;
}

.account_info {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-right: 10px;
}

.account_pfp {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 50%;
  user-select: none;
}

.account_name {
  margin: 0;
  font-size: 13px;
  color: black;
  font-weight: 500;
  user-select: none;
}

.account_position {
  margin: 0;
  font-size: 13px;
  color: black;
  font-weight: 400;
  user-select: none;
}

.menu {
  position: absolute;
  right: 20px;
  top: 60px;
  width: 200px;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(164, 155, 155, 0.5);
  border-radius: 2.5px;
  /* border: 1px solid black; */
  z-index: 1;
}

.menu_item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px;
  height: 50px;
  cursor: pointer;
}

.menu_item:hover {
  background-color: rgba(0, 61, 130, 0.1);
  /* border-radius: 2.px; */
}

.menu_item_text {
  color: black;
  opacity: 1;
  margin: 0;
  margin-left: 15px;
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.account_menu_icon {
  width: 20px;
  height: 20px;
  user-select: none;
}

.menu_divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}

.notifications_sidebar {
  position: fixed;
  top: 60px;
  left: 250px;
  width: 350px;
  height: calc(100vh - 80px);
  /* background-color: rgba(30, 30, 30, 1); */
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4) !important;
  z-index: 999999;
  padding: 20px;
  /* your other styles */
}

.exit_button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  width: 230px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
}

.exit_button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.exit_text {
  color: black;
  font-size: 16px;
  font-weight: 300;
  opacity: 0.7;
}

.edit_chatbot_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 61, 130, 1);
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  box-sizing: border-box;
  padding: 10px;
  padding-right: 0;
  position: relative;
}

.edit_chatbot_button:hover,
.edit_chatbot_button.dropdown_open /* Adding dropdown open state */ {
  background-color: rgba(0, 61, 130, 1);
  color: white;
}

.edit_chatbot_button {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown_icon {
  opacity: 0;
  width: 20px;
  height: 20px;
  transition: opacity 0.2s, transform 0.2s;
  margin-right: -20px;
  user-select: none;
}

.non_selectable_text {
  user-select: none;
}

.edit_chatbot_button_text {
  margin-left: 30px;
  margin-right: 30px;
  color: rgba(0, 61, 130, 1);
  user-select: none;
}

.edit_chatbot_button_text {
  transition: transform 0.2s;
  font-weight: 600;
}

.edit_chatbot_button:hover .dropdown_icon,
.edit_chatbot_button.dropdown_open .dropdown_icon {
  opacity: 1;
  transform: translateX(-30px);
}

.edit_chatbot_button.dropdown_open .dropdown_icon {
  transform: translateX(-30px) rotate(180deg);
}

.edit_chatbot_button:hover .edit_chatbot_button_text,
.edit_chatbot_button.dropdown_open .edit_chatbot_button_text {
  transform: translateX(-10px);
  color: white;
}

.affiliate_header {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 230px; */
  padding: 0 20px;
  box-sizing: border-box;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  background-color: #00be6d;
}

.affiliate_text {
  color: white;
  font-size: 16px;
  font-weight: 400;
  /* opacity: 0.7; */
}

.page_title {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: black;
  margin: 0;
  margin-left: 20px;
}

.hide_info_text {
  color: black;
  font-size: 16px;
  font-weight: 300;
  opacity: 0.7;
  user-select: none;
}

.switch_info_button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.1); */
  border: 1px solid rgba(0, 61, 130, 0.75);
  width: 175px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.switch_info_button:hover {
  background-color: rgba(0, 61, 130, 0.075);
}

.switch {
  width: 52px;
  height: 24px;
  border-radius: 15px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
}

.switch.on {
  background-color: rgba(0, 61, 130, 1);
}

.slider {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 15px;
  position: absolute;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.5);
}

.slider:hover {
  width: 24px;
  height: 24px;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0 0px 9px rgba(0, 0, 0, 0.5);
}

.switch.on .slider {
  transform: translateX(33.3px);
}

.container_button {
  position: relative;
}

.dropdown_menu {
  position: absolute;
  background-color: white;
  border-radius: 5px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  top: 110%;
  left: 0;
  width: calc(100% - 10px);
  box-shadow: 0px 0px 5px rgba(164, 155, 155, 0.5);
  /* background-color: rgba(0, 61, 130, 1); */
  max-height: 350px;
  overflow-y: auto;
  margin-left: 10px;
  border: 1px solid rgba(0, 61, 130, 0.5);
}

.dropdown_menu_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 50px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid rgba(0, 61, 130, 0.1);
  color: rgba(0, 61, 130, 1);
}

.dropdown_menu_item:hover {
  background-color: rgba(0, 61, 130, 0.1);
}

.getModelDate {
  color: black;
  opacity: 0.6;
  font-size: 12px;
}

.dropdown_menu li {
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
}

.dropdown_menu li:hover {
  background-color: #f0f0f0;
}

.rotate_180 {
  transform: rotate(180deg);
  transition: transform 0.1s ease;
}

.menu_divider_box {
  display: flex;
  justify-content: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  height: 1px;
}

.menu_divider_small {
  width: 85%;
  box-sizing: border-box;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}
