.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 9999; /* Ensures it's above the page content but below the modal */
}

.modalPaper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  padding: 10px 0;
  width: 350px;
  /* min-height: 300px; */
  overflow-y: auto;
  border-radius: 8px;
  padding: 20px;
}

.modal_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.modal_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
}

.popup_text {
  color: black;
  opacity: 0.7;
  margin: 0;
  margin-top: 20px;
}

.popup_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 95%;
  justify-content: space-between;
}
.cancel_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 45px;
  border: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  color: white;
}
.delete_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 45px;
  border: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.cancel_button:hover {
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0.75;
}
.delete_button:hover {
  background-color: rgba(0, 0, 0, 0.75);
  color: #f00;
  opacity: 0.75;
}

.modal_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.create_button_disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 45px;
  border: 5px;
  background-color: rgba(0, 61, 130, 1);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
  opacity: 0.2;
}

.create_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 45px;
  border: 5px;
  background-color: rgba(0, 61, 130, 1);
  border-radius: 5px;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
}

.create_button:hover {
  background-color: rgba(0, 61, 130, 0.8);
  color: rgba(255, 255, 255, 1);
}

.image_icon {
  width: 50%;
  height: 50%;
}

.input_name_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  height: 50px;
  width: 95%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 5px 15px;
  border-radius: 2.5px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 200px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  user-select: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.input_file_box {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: none;
  outline: none;
  height: 50px;
  width: 95%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 5px 15px;
  border-radius: 2.5px;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 200px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  user-select: none;

  margin-bottom: 20px;
}

.input_name {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
}

.input_file {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-grow: 1;
  background-color: transparent;
  border: none;
  outline: none;
}

.browse_files {
  margin: 0;
  font-size: 12px;
  color: rgba(0, 61, 130, 1);
  cursor: pointer;
  z-index: 99;
}

.browse_files:hover {
  color: rgba(0, 61, 130, 1);
}

.hidden_input_file {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: pointer;
  /* width: 1px;
  height: 1px; */
}

.input_file_text_grayed {
  margin: 0;
  color: rgba(0, 0, 0, 0.2);
}

.input_file_text {
  margin: 0;
  color: rgba(0, 0, 0, 0.8);
}

.cross_background {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.065);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.red_cross_svg {
  stroke: rgba(200, 0, 0, 0.75);
}

.cross_background:hover {
  background-color: rgba(0, 0, 0, 0.085);
}
