.main_layout {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.page_title {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: black;
  margin: 0;
  margin-bottom: 20px;
}

.integration_tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px; /* adjust this value as needed */
  /* add any additional styles as needed */
}

.tile {
  position: relative;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  /* height: 100%; */
  border-radius: 3px;
  box-sizing: border-box;
  overflow: hidden;
  height: 300px;
  /* overflow-y: auto; */
}

.tile_header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background-color: rgba(245, 119, 34, 0.1); */
}

.logo_image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  height: 50%;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
}

.title {
  margin: 0;
  color: white;
  font-size: 15px;
  font-weight: 400;
}

.type {
  margin: 0;
  color: white;
  font-size: 15px;
  font-weight: 300;
  margin-bottom: 10px;
}

.summary {
  margin: 0;
  color: white;
  font-size: 15px;
  text-align: left;
  font-weight: 300;
  max-height: 15px;
}

.connect_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background-color: rgba(16, 148, 16, 0.5);
  width: 30%;
  height: 30px;
  border-radius: 30px;
  color: white;
}

.connect_button:hover {
  background-color: rgba(0, 255, 0, 0.75);
}

.shadow_overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%; /* Adjust this value based on the desired overlay height */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
}
