/* styles.css */
.modal {
  /* Styles for the modal container */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #161616;
  border: 1px solid rgba(44, 78, 133, 0.25);
  padding: 20px;
}

.overlay {
  /* Styles for the overlay */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.filter_icon {
  width: 24px;
  height: 24px;
}

.rippleButton {
  display: inline-block;
  position: relative;
  overflow: hidden;
  user-select: none;
  padding: 10px;
  /* background: #f1f1f1; */
  border-radius: 5px;
  cursor: pointer;
}

.ripple {
  position: absolute;
  border-radius: 50%;
  background-color: #2ebb55;
  transform: scale(0);
  animation: ripple 300ms ease-out;
}

@keyframes ripple {
  to {
    transform: scale(2);
    opacity: 0;
  }
}

.filter_icon {
  display: block;
}

/* SEARCH HEADER */
.top_controls {
  width: 100%;
  margin-bottom: 20px;
}

.top_controls_top {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 20px;
}
.page_title {
  font-size: 35px;
  font-weight: 100;
  color: white;
  margin: 0;
}
.filter_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: 5px;
}

.filter_box_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 78, 133, 0.2);
  border-radius: 5px;
}

.filter_icon {
  width: 24px;
  height: 24px;
}

.search_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.search_icon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.search_box_input {
  color: white;
  width: 75%;
  height: 80%;
  border: none;
  margin-left: 10px;
  background-color: transparent;
  outline: none;
}

.search_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  min-height: 45px;
  border-radius: 2.5px;
  margin-bottom: 20px;
}

.search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 90%; */
  flex-grow: 1;
  height: 100%;
  /* background-color: rgba(50, 50, 50, 0.2); */
  margin-right: 15px;
  border-radius: 5px;
  border: 1px solid rgba(44, 78, 133, 0.75);
}

.search_icon {
  width: 30px;
  height: 30px;
  opacity: 0.25;
}

.search_divider {
  height: 50%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 5px;
  margin-right: 5px;
}
.search_input {
  height: 80%;
  color: rgba(0, 0, 0, 1);
  background-color: transparent;
  border: none;
  outline: none;
}

.search_input::placeholder {
  opacity: 0.5;
}

.search_filter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: rgba(0, 61, 130, 1);
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.filter_menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100%; /* Position it right below the filter icon */
  right: 0;
  margin-top: 20px;
  min-width: 300px; /* Minimum width for the dropdown, adjust as needed */
  background-color: #fff;
  border: 1px solid rgba(0, 61, 130, 1);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other elements */
  padding: 8px 0;
}

.filter_menu_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  height: 100%;
  width: 90%;
}

.search_filter:hover {
  background-color: rgba(0, 61, 130, 0.8);
}

.search_filter_icon {
  width: 70%;
  height: 70%;
}

.interest_tag {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.interest_dot {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  margin-right: 10px;
}

.menu {
  position: absolute;
  right: 0px;
  top: 50px;
  width: 140px;
  /* height: 200px; */
  /* background-color: white; */
  background-color: rgba(250, 250, 250, 1);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  /* border: 1px solid black; */
  z-index: 9999;
}

.menu_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 46px;
}

.menu_item:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.menu_item_text {
  color: black;
  font-size: 12px;
  opacity: 0.65;
  margin: 0;
  margin-left: 10px;
}

/* .interest_tag:hover {
  opacity: 0.85;
} */

.interest_tag_text {
  color: white;
  margin: 0;
  padding: 5px;
  font-size: 12px;
}

.highlight_menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
}

.tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
}

.highlight_box {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  height: 100%;
  width: 130px;
  margin-right: 15px;
  border-radius: 20px;
  cursor: pointer;
}

.highlight_box:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.active {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  color: black;
  height: 100%;
  width: 130px;
  margin-right: 15px;
  border-radius: 20px;
  cursor: pointer;
}

.buttons {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  background-color: rgba(0, 61, 130, 1);
  color: white;
  /* font-weight: 200; */
  height: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.save_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  outline: 1px solid rgba(0, 61, 130, 1);
  color: rgba(0, 61, 130, 1);
  /* font-weight: 200; */
  height: 100%;
  border-radius: 5px;
  margin-left: 15px;
  cursor: pointer;
}

.filter_section {
  width: 300px;
}

.filter_section_title {
  display: flex;
  align-self: start;
}

.deal_stages_container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Space between items */
}

.deal_stages_item {
  /* background-color: rgba(0, 0, 0, 0.5); */
  border: 1px solid rgba(0, 61, 130, 1);
  padding: 0px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
}

/* .deal_stages_item:hover {
  margin: 0;
  background-color: rgba(0, 61, 130, 1);
} */

.filter_interest_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.deal_item_text {
  color: rgba(0, 61, 130, 1);
  font-size: 10px;
}

.filter_interest_checkbox {
  height: 15px;
  width: 15px;
  border-radius: 2.5px;
  border: 1px solid rgba(0, 61, 130, 1);
}

.filter_interest_text {
  color: rgba(0, 61, 130, 1);
  font-size: 10px;
  margin: 0;
  margin-left: 7.5px;
}

.sort_option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 35px;
  width: 100%;
}

.sort_divider {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 61, 130, 0.5);
}

.svg_rotate {
  width: 3.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

circle {
  fill: none;
  stroke: white;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

.circular_loader {
  height: 100%;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}
.loading_circle {
  fill: none;
  stroke: white;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}
.circular_loader_circle {
  stroke: white;
}

.add_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: rgba(44, 78, 133, 0.2);
  border: 1px solid rgba(44, 78, 133, 1);
  border-radius: 5px;
  position: relative;
  cursor: pointer;
}

.add_button:hover {
  background-color: rgba(44, 78, 133, 0.35);
}
