.main_layout {
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 100%;
}

.page_title {
  align-self: start;
  font-size: 30px;
  font-weight: 300;
  color: black;
  margin: 0;
  margin-bottom: 20px;
}

.column_titles {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
  padding-left: 15px;
  box-sizing: border-box;
  border-radius: 2.5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.column_title {
  display: flex;
  align-items: start;
  width: 20%;
}

.column_title_info {
  display: flex;
  align-items: start;
  width: 25%;
}

.column_title_last {
  display: flex;
  align-items: start;
  width: 15%;
}

.user_profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background-color: rgba(0, 0, 0, 0.15); */
  width: 100%;
  height: 60px;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3) !important;
  padding-left: 15px;
  box-sizing: border-box;
}

.user_profile_layout {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 25%;
}

.user_profile_image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  /* margin-left: 15px; */
}

.user_profile_text {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 15px;
}

.user_profile_name {
  color: rgba(0, 0, 0, 0.9);
  font-size: 16px;
  margin: 0;
}

.user_profile_genre {
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  margin: 0;
}

.profile_chat_button {
  width: 35px;
  height: 35px;
  background-color: rgba(46, 187, 85, 0.6);
  margin-right: 15px;
  border-radius: 50%;
}

.profile_more_button {
  width: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.15);
  margin-right: 15px;
  border-radius: 50%;
}

.follow_up_layout {
  /* display: flex;
    justify-content: start;
    align-items: center;
    width: 20%;
    height: 30px;
    border: 1px solid rgba(0, 61, 130, 0.75);
    border-radius: 5px; */
}

.datePicker input {
  height: 30px !important;
  /* border: none !important;
    outline: none !important; */
}

.deal_stage_layout {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
  height: 40px;
  /* background-color: rgba(0, 61, 130, 0.5); */
  border: 1px solid rgba(0, 61, 130, 1);
  /* border: 1px solid rgba(0, 0, 0, 0.3); */
  /* background-color: rgba(0, 0, 0, 0.05); */
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
}

.deal_stage_layout:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.dropdown_icon {
  width: 17.5px;
  height: 17.5px;
  object-fit: cover;
}

.cell_text_content {
  /* color: rgba(0, 61, 130, 1); */
  color: black;
  opacity: 0.75;
  font-size: 11px;
}

.interest_level_layout {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 15%;
  height: 30px;
}

.interest_level_tag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  background-color: rgba(0, 130, 15, 0.75);
  border-radius: 5px;
  color: white;
  font-size: 14px;
}

.prospect_scroll {
  width: 100%;

  flex-grow: 1;
  padding: 1px;
  box-sizing: border-box;
  overflow-y: auto;
  margin-bottom: 10px;
}

.deal_stage_menu {
  position: absolute;
  left: -2px;
  top: 50px;
  width: calc(100% + 4px);

  background-color: rgba(250, 250, 250, 1);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;

  z-index: 1;
  padding: 7px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 61, 130, 1);
}

.active_menu {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 100%;

  border-radius: 5px;
  /* background-color: rgba(0, 61, 130, 0.1); */
  /* padding: 5px; */
  box-sizing: border-box;
}

.inactive_menu {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.04);
  /* padding: 5px; */
  box-sizing: border-box;
}

.menu_title_item {
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
}

.menu_title {
  color: black;
  font-size: 14px;
  margin: 0;
  margin-left: 10px;
  opacity: 0.9;
  font-weight: 400;
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.menu_item_text {
  /* color: black; */
  opacity: 0.7;
  font-size: 12px;
  margin: 0;
  margin-left: 10px;
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  font-weight: 300;
}

.menu_item {
  color: black;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
}

.menu_item:hover {
  color: black;
  background-color: rgba(0, 61, 130, 0.4);
  border-radius: 5px;
  opacity: 1;
}

.buffer {
  height: 20px;
}
